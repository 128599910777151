import config from './config';

// add country that we would support the domains for in future.
export const domainRegions = {
  INTERNATIONAL: 'international',
  PHILIPPINES: 'philippines'
};

export const getDomainRegion = () => {
  return config.domainRegion ?? domainRegions.INTERNATIONAL;
};
