import { newFooterIcons } from '@/utility/constants';
import {
  buildMobileLinks,
  getIosAppStoreImgProps,
  getPlayStoreImgProps,
  getRegionSpecificLogoData
} from '@/components/common/NewFooterV2/helper';

import CaretDownIcon from '@/components/common/Icons/CaretDownIcon';
import Link from 'next/link';
import NextImage from '@/components/common/NextImage';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import config from '@/utility/config';
import { getDomainRegion } from '@/utility/domainRegion';
import { scrollToTop } from '@/utility/domHelpers';
import style from './NewFooter.module.scss';
import { useUserSessionContext } from '@/contexts/UserSessionContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { useStartCta } from '@/hooks/useStartCta';
import { FOOTER_ITEM_CLICK_EVENT } from '@/utility/analyticsConsts';
import { trackGAEvent } from '@/utility/analytics';

const NAS_ACADEMY_FB_PAGE = 'https://www.facebook.com/nascommunities';
const NAS_ACADEMY_IG_PAGE = 'https://www.instagram.com/nas.io/';
const NAS_ACADEMY_TT_PAGE = 'https://www.tiktok.com/@nascommunity';
const NAS_ACADEMY_TW_PAGE = 'https://twitter.com/Nascommunities';
const NAS_ACADEMY_YT_PAGE =
  'https://www.youtube.com/@nascommunities/featured';
const NAS_ACADEMY_LI_PAGE =
  'https://www.linkedin.com/company/nas-company/';

const componentId = 'footer';
const className = 'c-NewFooter';

function footerIconListItem(socialMedia, key, view) {
  const SpecificFooterIcon = newFooterIcons[socialMedia.name];
  const analyticsId = `${componentId}-${view}-${socialMedia.name}`
    .toLocaleLowerCase()
    .replace(/ /g, '-');
  return (
    <li key={key} className="mr-18 last:mr-0" id={analyticsId}>
      <a
        title={socialMedia.title}
        href={socialMedia.href}
        target="_blank"
        rel="noopener noreferrer">
        <SpecificFooterIcon
          customClassNames={'footer-icon'}
          width="24px"
          height="24px"
          viewBox="0 0 20 20"
        />
      </a>
    </li>
  );
}

const NewFooter = ({
  showSocialMediaIcons,
  showBackToTop,
  showNavLinks,
  socialIcons = [
    {
      title: 'Youtube Page',
      href: NAS_ACADEMY_YT_PAGE,
      name: 'youtube'
    },
    {
      title: 'Instagram Page',
      href: NAS_ACADEMY_IG_PAGE,
      name: 'instagram'
    },
    {
      title: 'Twitter Page',
      href: NAS_ACADEMY_TW_PAGE,
      name: 'twitter'
    },
    {
      title: 'Tiktok Page',
      href: NAS_ACADEMY_TT_PAGE,
      name: 'tiktok'
    },
    {
      title: 'Linked In Page',
      href: NAS_ACADEMY_LI_PAGE,
      name: 'linkedin'
    },
    {
      title: 'Facebook Page',
      href: NAS_ACADEMY_FB_PAGE,
      name: 'facebook'
    }
  ]
}) => {
  const { isDesktopView, isGtEqLg } = useWindowWidthContext();
  const { sessionHomePageLogoLink } = useUserSessionContext();
  const logoSrc = getRegionSpecificLogoData({ region: getDomainRegion() });

  function renderSocialMediaIcons() {
    return (
      <ul className="flex flex-row justify-center mb-12 md:mb-0 xl:justify-start footer-icon-list">
        {socialIcons?.map((socialMedia, key) => {
          return footerIconListItem(
            socialMedia,
            key,
            isDesktopView ? 'desktop' : 'mobile'
          );
        })}
      </ul>
    );
  }

  const { link, label } = useStartCta();

  const displayedMobileLinks = useMemo(() => {
    return buildMobileLinks(label, link);
  }, [label, link]);

  const renderLinks = () => {
    return (
      <div className="flex flex-row flex-wrap justify-between w-full md:justify-start lg:justify-evenly">
        {showNavLinks &&
          displayedMobileLinks.map((col, key) => {
            return (
              <div
                key={key}
                className="flex flex-col w-[180px] mb-40 md:mb-0 md:mr-[60px] md:last-of-type:mr-0 md:first-of-type:mr-0">
                <div className="text-12 font-medium text-[#FBFCFF] pb-20 uppercase">
                  {col?.title}
                </div>
                {col?.links?.map((linkItem, index) => {
                  return (
                    <a
                      key={index}
                      href={linkItem?.link}
                      onClick={() => {
                        if (linkItem?.link)
                          trackGAEvent(FOOTER_ITEM_CLICK_EVENT, {
                            link: linkItem?.link
                          });
                      }}
                      target={
                        isGtEqLg && linkItem?.isExternal ? '_blank' : ''
                      }
                      rel="noopener noreferrer"
                      className="text-14 font-medium text-[#AAABAE] pb-16 hover:text-[#FBFCFF] transition-colors">
                      {linkItem?.label}
                    </a>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div
      className={`w-full px-16 py-40 md:px-40 md:py-48 bg-white-default font-poppins `}>
      <div className="bg-neutral-10 rounded-[40px] px-24 py-48 md:py-80 md:px-40 lg:mx-auto max-w-[1120px] xl:px-80">
        <div
          className={`
          flex flex-col lg:flex-row lg:justify-between
          `}>
          <div className="flex flex-col w-full lg:min-w-[154px] lg:max-w-[154px]">
            <div className="font-extrabold text-skin-white">
              <Link href={sessionHomePageLogoLink} title="Home Page">
                <div className="cursor-pointer">
                  <div className={`${style[`${className}__logo`]} mb-8`}>
                    <NextImage {...logoSrc} />
                  </div>
                  <div className="mb-24 font-medium text-body-sm text-white-emp whitespace-nowrap xl:text-body-md">
                    Bring people together
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex flex-row mt-8 lg:flex-col gap-18 lg:mt-14">
              <a
                href={`${config.appleAppStoreLink}`}
                target="_blank"
                rel="noopener noreferrer">
                <NextImage {...getIosAppStoreImgProps()} />
              </a>
              <a
                href={config.googlePlayStoreLink}
                target="_blank"
                rel="noopener noreferrer">
                <NextImage {...getPlayStoreImgProps()} />
              </a>
            </div>
          </div>
          <div className="flex justify-center w-full mt-40 lg:mt-0">
            <div className="w-full lg:pl-[80px]">{renderLinks()}</div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between w-full mt-64">
          <div className="flex flex-col text-center md:flex-row md:items-center xl:text-left">
            {showSocialMediaIcons && renderSocialMediaIcons()}
            <div className={`flex text-light-aa text-12`}>
              <a
                href="/community-manager-terms"
                onClick={() => {
                  trackGAEvent(FOOTER_ITEM_CLICK_EVENT, {
                    link: '/community-manager-terms'
                  });
                }}
                className="text-light-aa text-12 mr-8 md:ml-24 hover:text-[#FBFCFF] transition-colors">
                Terms of service
              </a>
              <a
                href="/privacy"
                onClick={() => {
                  trackGAEvent(FOOTER_ITEM_CLICK_EVENT, {
                    link: '/privacy'
                  });
                }}
                className="text-light-aa text-12 hover:text-[#FBFCFF] transition-colors">
                Privacy
              </a>
            </div>
          </div>
          <p
            className={`text-light-aa text-12 whitespace-nowrap mt-8 md:mt-0`}>
            Copyright © {new Date().getFullYear()}
          </p>
        </div>

        {showBackToTop && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            id={`${componentId}-back-to-top`}
            className="flex justify-center py-[18px] cursor-pointer"
            onClick={scrollToTop}>
            <p className="hidden font-bold text-skin-base text-16 xl:block">
              Back to Top
            </p>
            <div className="xl:pl-[8px] bg-white-emp xl:bg-transparent w-[48px] h-[48px] xl:w-auto xl:h-auto flex items-center justify-center rounded-full">
              <CaretDownIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NewFooter.defaultProps = {
  showBackToTop: false,
  showSocialMediaIcons: true,
  showNavLinks: true,
  hasStickyBottomNav: false
};

NewFooter.propTypes = {
  showNavLinks: PropTypes.bool,
  showSocialMediaIcons: PropTypes.bool,
  hasStickyBottomNav: PropTypes.bool
};

export default NewFooter;
