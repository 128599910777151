import React from 'react';

const className = 'c-CaretDownIcon';

const CaretDownIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l6 6 6-6"></path>
    </svg>
  );
};

CaretDownIcon.defaultProps = {
  customClassNames: ''
};

export default CaretDownIcon;
