import {
  communitiesPageRoute,
  discoveryPageRoute
} from '@/utility/routesHelper';

import { NA_CONTACT_EMAIL } from '@/utility/constants';
import { domainRegions } from '@/utility/domainRegion';

export const logoSrc = {
  alt: 'logo',
  mobileImgProps: {
    id: 'footer-mobile-logo',
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/nas_io_footer.svg',
    width: 128,
    height: 28
  },
  desktopImgProps: {
    id: 'footer-desktop-logo',
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/nas_io_footer.svg',
    width: 128,
    height: 28
  }
};

export const phLogoSrc = {
  alt: 'logo',
  mobileImgProps: {
    id: 'footer-mobile-logo',
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/nas_io_footer.svg',
    width: 128,
    height: 28
  },
  desktopImgProps: {
    id: 'footer-desktop-logo',
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/nas_io_footer.svg',
    width: 128,
    height: 28
  }
};

export const getRegionSpecificLogoData = ({ region }) => {
  switch (region) {
    case domainRegions.PHILIPPINES:
      return phLogoSrc;
    default:
      return logoSrc;
  }
};

export const desktopFooterLinks = [
  {
    id: 'footer-desktop-classes',
    name: 'Classes',
    link: discoveryPageRoute,
    isExternal: false
  },
  {
    id: 'footer-desktop-refer-a-friend',
    name: 'Refer a friend',
    link: '/referral',
    isExternal: false
  },
  {
    id: 'footer-desktop-communities',
    name: 'Communities',
    link: communitiesPageRoute,
    isExternal: false
  },
  {
    id: 'footer-desktop-for-business',
    name: 'For Business',
    link: '/business',
    isExternal: false
  },
  {
    id: 'footer-desktop-about-us',
    name: 'About Us',
    link: '/about-us',
    isExternal: false
  },
  {
    id: 'footer-mobile-privacy',
    name: 'Privacy T&C',
    link: '/privacy',
    isExternal: false
  },
  {
    id: 'footer-desktop-impact',
    name: 'Impact',
    link: '/impact',
    isExternal: false
  },
  {
    id: 'footer-desktop-blog',
    name: 'Blog',
    link: '/blog',
    isExternal: false
  }
];

export const baseMobileFooterLinks = [
  {
    id: 'footer-desktop-classes',
    name: 'Classes',
    link: discoveryPageRoute,
    isExternal: false
  },
  {
    id: 'footer-desktop-refer-a-friend',
    name: 'Refer a friend',
    link: '/referral',
    isExternal: false
  },
  {
    id: 'footer-desktop-communities',
    name: 'Communities',
    link: communitiesPageRoute,
    isExternal: false
  },
  {
    id: 'footer-desktop-for-business',
    name: 'For Business',
    link: '/business',
    isExternal: false
  },
  {
    id: 'footer-desktop-about-us',
    name: 'About Us',
    link: '/about-us',
    isExternal: false
  },
  {
    id: 'footer-mobile-privacy',
    name: 'Privacy T&C',
    link: '/privacy',
    isExternal: false
  },
  {
    id: 'footer-desktop-impact',
    name: 'Impact',
    link: '/impact',
    isExternal: false
  },
  {
    id: 'footer-desktop-blog',
    name: 'Blog',
    link: '/blog',
    isExternal: false
  }
];

const baseExtraLinks = [
  {
    name: 'Affiliate',
    link: '/affiliates',
    isExternal: false
  },
  {
    name: 'Contact us',
    link: `mailto:${NA_CONTACT_EMAIL}`,
    isExternal: false
  },
  {
    name: 'Student T&C',
    link: '/enrollment-terms',
    isExternal: false
  },
  {
    name: 'Privacy',
    link: '/privacy',
    isExternal: false
  }
];

export const getFooterLinks = (baseLinks) => {
  return baseLinks;
};

export const mobileLinks = [
  {
    title: 'Nas.io',
    links: [
      {
        label: 'Start for free',
        link: '/start-community'
      },
      {
        label: 'Login',
        link: '/login'
      },
      {
        label: 'Pricing',
        link: '/pricing'
      }
    ]
  },
  {
    title: 'Features',
    links: [
      {
        label: 'WhatsApp Communities',
        link: '/whatsapp'
      },
      {
        label: 'Event hosting',
        link: '/features/live-events'
      },
      {
        label: 'Paid membership',
        link: '/features/monetization'
      },
      {
        label: 'Newsletters',
        link: '/features/magic-reach'
      },
      {
        label: 'Email Marketing',
        link: '/features/magic-reach'
      },
      {
        label: 'Online course',
        link: '/features/content-library'
      },
      {
        label: 'Chat Integration',
        link: '/features/chat-integrations'
      },
      {
        label: 'Website building',
        link: '/features/public-page'
      },
      {
        label: 'Analytics',
        link: '/features/analytics'
      },
      {
        label: 'Token gating',
        link: '/features/web3-tools'
      }
    ]
  },
  {
    title: 'Company',
    links: [
      {
        label: 'About Us',
        link: '/about-us'
      },
      {
        label: 'Blog',
        link: `/blog`,
        isExternal: true
      },
      {
        label: 'Career',
        link: '/jobs',
        isExternal: true
      },
      {
        label: 'Academy',
        link: 'https://nasacademy.com/',
        isExternal: true
      }
    ]
  }
];

export const getPlayStoreImgProps = () => {
  return {
    alt: 'Google Play store',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/play-store-download-button.png',
      width: 128,
      height: 38
    }
  };
};

export const getIosAppStoreImgProps = () => {
  return {
    alt: 'Apple App store',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/app-store-download-button.png',
      width: 128,
      height: 38
    }
  };
};

export const extraFooterLinks = getFooterLinks(baseExtraLinks);
export const mobileFooterLinks = getFooterLinks(baseMobileFooterLinks);

export const buildMobileLinks = (ctaLabel, ctaLink) => {
  return [
    {
      title: 'Nas.io',
      links: [
        {
          label: ctaLabel,
          link: ctaLink
        },
        {
          label: 'Login',
          link: '/login'
        },
        {
          label: 'Pricing',
          link: '/pricing'
        }
      ]
    },
    {
      title: 'Features',
      links: [
        {
          label: 'WhatsApp Communities',
          link: '/whatsapp'
        },
        {
          label: 'Event hosting',
          link: '/features/live-events'
        },
        {
          label: 'Paid membership',
          link: '/features/monetization'
        },
        {
          label: 'Email Marketing',
          link: '/features/magic-reach'
        },
        {
          label: 'Newsletters',
          link: '/features/magic-reach'
        },
        {
          label: 'Online course',
          link: '/features/content-library'
        },
        {
          label: 'Chat Integration',
          link: '/features/chat-integrations'
        },
        {
          label: 'Website building',
          link: '/features/public-page'
        },
        {
          label: 'Analytics',
          link: '/features/analytics'
        },
        {
          label: 'Token gating',
          link: '/features/web3-tools'
        }
      ]
    },
    {
      title: 'Company',
      links: [
        {
          label: 'For Business',
          link: '/business'
        },
        {
          label: 'About Us',
          link: '/about-us'
        },
        {
          label: 'Blog',
          link: `/blog`,
          isExternal: true
        },
        {
          label: 'Career',
          link: '/jobs',
          isExternal: true
        },
        {
          label: 'Academy',
          link: 'https://nasacademy.com/',
          isExternal: true
        }
      ]
    }
  ];
};
